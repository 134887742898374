import { Alert, Button, Col, ListGroup, Row, Tab } from "react-bootstrap"

const Documents = () => {
    return (
        <div>
            <Col>
                <br />
                <Alert variant='danger'>
                    Actes - Documentació
                </Alert>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link4">
                    <Row>
                        <Col sm={4}>
                        <ListGroup>
                            <ListGroup.Item action href="#link4">
                                Reglament de règim intern VVC
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link3">
                                Accidents esportius FCVB 22-23
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link1">
                                Comportaments esportius
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link2">
                                Assemblea General 16/09/2022
                            </ListGroup.Item>
                        </ListGroup>
                        </Col>
                        <Col sm={8}>
                            <Tab.Content>
                                <Tab.Pane eventKey="#link1">
                                    <div>
                                        {" "}
                                        <p> Pautes de comportaments bàsics pel bon funcionament esportiu i formatiu dels components dels equips: entrenadors/es, delegats/des i esportistes</p>
                                        <Button variant="outline-info" size="lg" href="/docs/Compromisos.pdf">Obrir el document</Button>
                                    </div> 
                                </Tab.Pane>
                                <Tab.Pane eventKey="#link2">
                                <div>
                                        {" "}
                                        <p> Informe anual de l'Assemblea General que es va celebrar el passat 16/09/2022</p>
                                        <Button variant="outline-info" size="lg" href="/docs/Acta-Assemblea-16-9-2022.pdf">Obrir l'acte</Button>
                                    </div> 
                                </Tab.Pane>
                                <Tab.Pane eventKey="#link3">
                                <div>
                                        {" "}
                                        <p> Protocòl d'actuació en cas d'accidents esportius</p>
                                        <Button variant="outline-info" size="lg" href="/docs/PROTOCOL-DACTUACIO-FCVB-AXA-ITEGRA.pdf">Obrir el document</Button>
                                    </div> 
                                </Tab.Pane>
                                <Tab.Pane eventKey="#link4">
                                <div>
                                        {" "}
                                        <p> Reglament de règim intern VVC</p>
                                        <Button variant="outline-info" size="lg" href="/docs/Reglament regim intern VVC.pdf">Obrir el document</Button>
                                    </div> 
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Col>
        </div>
    )
}

export default Documents