import * as React from 'react';
import './Entrenaments.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Badge } from 'react-bootstrap';
import { Button } from 'reactstrap';

const Entrenaments = props => {
    const [isEquipTots, setEquipTots] = React.useState(true);
    const [isEquipSenior, setEquipSenior] = React.useState(false);
    const [isEquipJuniorA, setEquipJuniorA] = React.useState(false);
    const [isEquipJuniorB, setEquipJuniorB] = React.useState(false);
    const [isEquipJuvenilA, setEquipJuvenilA] = React.useState(false);
    const [isEquipJuvenilB, setEquipJuvenilB] = React.useState(false);
    const [isEquipJuvenilC, setEquipJuvenilC] = React.useState(false);
    const [isEquipJuvenilM, setEquipJuvenilM] = React.useState(false);
    const [isEquipCadetA, setEquipCadetA] = React.useState(false);
    const [isEquipCadetB, setEquipCadetB] = React.useState(false);
    const [isEquipCadetC, setEquipCadetC] = React.useState(false);
    const [isEquipCadetM, setEquipCadetM] = React.useState(false);
    const [isEquipInfantilA, setEquipInfantilA] = React.useState(false);
    const [isEquipInfantilB, setEquipInfantilB] = React.useState(false);
    const [isEquipInfantilM, setEquipInfantilM] = React.useState(false);
    const [isEquipAleviA, setEquipAleviA] = React.useState(false);
    const [isEquipAleviB, setEquipAleviB] = React.useState(false);
    const [isEquipAleviC, setEquipAleviC] = React.useState(false);
    const [isEquipBenjamiA, setEquipBenjemiA] = React.useState(false);
    const [isEquipBenjamiB, setEquipBenjemiB] = React.useState(false);
    const [isEquipVeterans, setEquipVeterans] = React.useState(false);

    const [isEquipTecnificacioCadet, setEquipTecnificacioCadet] = React.useState(false);
    const [isEquipTecnificacioInfantil, setEquipTecnificacioInfantil] = React.useState(false);

    const optionTotsFalse = () => {
        setEquipTots(false);
        setEquipSenior(false)
        setEquipJuniorA(false)
        setEquipJuniorB(false)
        setEquipJuvenilA(false)
        setEquipJuvenilB(false)
        setEquipJuvenilC(false)
        setEquipJuvenilM(false)
        setEquipCadetA(false)
        setEquipCadetB(false)
        setEquipCadetC(false)
        setEquipCadetM(false)
        setEquipInfantilA(false)
        setEquipInfantilB(false)
        setEquipInfantilM(false)
        setEquipAleviA(false)
        setEquipAleviB(false)
        setEquipAleviC(false)
        setEquipBenjemiA(false)
        setEquipBenjemiB(false)
        setEquipVeterans(false)
        setEquipTecnificacioInfantil(false)
        setEquipTecnificacioCadet(false)
    }

    const optionTots = () => {
        optionTotsFalse();
        setEquipTots(true)

    }
    const optionSenior = () => {
        optionTotsFalse();
        setEquipSenior(true)

    }    
    const optionJuniorA = () => {
        optionTotsFalse();
        setEquipJuniorA(true)

    }    
    const optionJuniorB = () => {
        optionTotsFalse();
        setEquipJuniorB(true)

    }    
    const optionJuvenilA = () => {
        optionTotsFalse();
        setEquipJuvenilA(true)

    }    
    const optionJuvenilB = () => {
        optionTotsFalse();
        setEquipJuvenilB(true)
    }    
    const optionJuvenilC = () => {
        optionTotsFalse();
        setEquipJuvenilC(true)
    }    
    const optionJuvenilM = () => {
        optionTotsFalse();
        setEquipJuvenilM(true)
    }    
    const optionCadetA = () => {
        optionTotsFalse();
        setEquipCadetA(true)
    }   
    const optionCadetB = () => {
        optionTotsFalse();
        setEquipCadetB(true)
    }   
    const optionCadetC = () => {
        optionTotsFalse();
        setEquipCadetC(true)
    }   
    const optionCadetM = () => {
        optionTotsFalse();
        setEquipCadetM(true)
    }   
    const optionInfantilA = () => {
        optionTotsFalse();
        setEquipInfantilA(true)
    }   
    const optionInfantilB = () => {
        optionTotsFalse();
        setEquipInfantilB(true)
    }   
    const optionInfantilM = () => {
        optionTotsFalse();
        setEquipInfantilM(true)
    }   
    const optionAleviA = () => {
        optionTotsFalse();
        setEquipAleviA(true)
    }   
    const optionAleviB = () => {
        optionTotsFalse();
        setEquipAleviB(true)
    }   
    const optionAleviC = () => {
        optionTotsFalse();
        setEquipAleviC(true)
    }   
    const optionBenjamiA = () => {
        optionTotsFalse();
        setEquipBenjemiA(true)
    } 
    const optionBenjamiB = () => {
        optionTotsFalse();
        setEquipBenjemiB(true)
    } 
    const optionVeterans = () => {
        optionTotsFalse();
        setEquipVeterans(true)
    }   
    const optionTecnificacioCadet = () => {
        optionTotsFalse();
        setEquipTecnificacioCadet(true)

    } 
    const optionTecnificacioInfantil = () => {
        optionTotsFalse();
        setEquipTecnificacioInfantil(true)

    } 

    
        return (     
            <div>
                <h2> HORARIS D’ENTRENAMENT<br/>TEMPORADA 24-25 </h2>   
                <div className="interButtons">
                    <Button color="danger" outline onClick={optionTots}>Tots</Button>{' '}
                    {/*<Button color="danger" outline  onClick={optionVeterans}>Máster</Button>*/}
                    <Button color="danger" outline  onClick={optionSenior}>Sènior</Button>{' '}
                    {/*<Button color="danger" outline  onClick={optionSeniorM}>Sènior Masculí</Button>{' '}*/}
                    <Button color="danger" outline  onClick={optionJuniorA}>Júnior A</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuniorB}>Júnior B</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuvenilA}>Juvenil A</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuvenilB}>Juvenil B</Button>
                    <Button color="danger" outline  onClick={optionJuvenilC}>Juvenil C</Button>
                    <Button color="danger" outline  onClick={optionJuvenilM}>Juvenil M</Button>
                    <Button color="danger" outline  onClick={optionCadetA}>Cadet A</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetB}>Cadet B</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetC}>Cadet C</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetM}>Cadet M</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilA}>Infantil A</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilB}>Infantil B</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilM}>Infantil M</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviA}>Alevi A</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviB}>Alevi B</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviC}>Alevi C</Button>{' '}
                    <Button color="danger" outline  onClick={optionBenjamiA}>Benjami A</Button>{' '}
                    <Button color="danger" outline  onClick={optionBenjamiB}>Benjami B</Button>{' '}
                </div>

                <VerticalTimeline layout='2-columns' animate='true'>
                    <h1><Badge bg="secondary posPadding">DILLUNS</Badge></h1>
                    {(isEquipTots || isEquipJuvenilC) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  } 
                        {(isEquipTots || isEquipCadetM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B5D7AB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5D7AB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET M</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>  } 

                        {(isEquipTots || isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#73CA95', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#73CA95', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B5D7AB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5D7AB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL M</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>}

                        {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#73CA95', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#73CA95', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>    }  
                        {(isEquipTots || isEquipJuniorB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#9D83B8', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9D83B8', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUNIOR B</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>    }  

                        {(isEquipTots || isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"C
                            contentStyle={{ background: '#C2B090', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="22:00 - 23:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C2B090', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}


                        <h1><Badge bg="secondary posPadding">DIMARTS</Badge></h1>
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 17:19"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}  
                        {(isEquipTots|| isEquipBenjamiA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">BENJAMÍ A</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots || isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9AC6E6', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9AC6E6', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipBenjamiB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FEE4CF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FEE4CF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">BENJAMI B</h3>
                            <h4 className="vertical-timeline-element-subtitle">LA Plana</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C2B090', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C2B090', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}


                        <h1><Badge bg="secondary posPadding">DIMECRES</Badge></h1>
                        {(isEquipTots|| isEquipJuvenilC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>  } 
                        {(isEquipTots|| isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipAleviB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B3A8D4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B3A8D4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#E56367', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E56367', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P2</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipAleviA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#E27C6D', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E27C6D', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ A</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipTecnificacioInfantil) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C2FFFF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C2FFFF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">Tecnificacio Infantil</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P3</h4>
                        </VerticalTimelineElement>}                        
                        {(isEquipTots|| isEquipAleviC) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#D9EAD4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D9EAD4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Escorial</h4>
                        </VerticalTimelineElement>}                        
                        {(isEquipTots|| isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#73CA95', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#73CA95', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callis</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#E56367', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E56367', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL M</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P2</h4>
                        </VerticalTimelineElement>    }  
                        {(isEquipTots || isEquipCadetM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B5D7AB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5D7AB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET M</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P3</h4>
                        </VerticalTimelineElement>} 
                        {(isEquipTots || isEquipTecnificacioCadet) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C2FFFF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C2FFFF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">Tecnificacio Cadet</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>}   
                        {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#73CA95', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#73CA95', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuniorA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#F0B3DB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#F0B3DB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUNIOR A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P2</h4>
                        </VerticalTimelineElement>  }   
                        {(isEquipTots || isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#D9D9D9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D9D9D9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">MASTER</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>  }  


                        <h1><Badge bg="secondary posPadding">DIJOUS</Badge></h1>
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 17:19"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}  
                        {(isEquipTots|| isEquipBenjamiA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">BENJAMÍ A</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots|| isEquipAleviC) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#D9EAD4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D9EAD4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Escorial</h4>
                        </VerticalTimelineElement>}                          
                        {(isEquipTots || isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9AC6E6', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9AC6E6', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipBenjamiB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FEE4CF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FEE4CF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">BENJAMI B</h3>
                            <h4 className="vertical-timeline-element-subtitle">LA Plana</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C2B090', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C2B090', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B5D7AB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5D7AB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL M</h3>
                            <h4 className="vertical-timeline-element-subtitle">LA Plana</h4>
                        </VerticalTimelineElement>}
                                                

                        <h1><Badge bg="secondary posPadding">DIVENDRES</Badge></h1>
                        {(isEquipTots|| isEquipInfantilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#E56367', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E56367', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL M</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }
                        {(isEquipTots|| isEquipInfantilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#E56367', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E56367', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipAleviB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#B3A8D4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B3A8D4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ B</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuniorA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#F0B3DB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#F0B3DB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUNIOR A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }
                        {(isEquipTots || isEquipAleviA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#E27C6D', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E27C6D', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ A</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement> }                        
                        {(isEquipTots || isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C2B090', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C2B090', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  }   
                        {(isEquipTots|| isEquipJuniorB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9D83B8', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9D83B8', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUNIOR B</h3>
                            <h4 className="vertical-timeline-element-subtitle">La Plana</h4>
                        </VerticalTimelineElement>}                   
                </VerticalTimeline>
            </div> 
            /*
                        // Entrenaments 2023-2024
            <div>
                <h2> HORARIS D’ENTRENAMENT<br/>TEMPORADA 23-24 </h2>   
                <div className="interButtons">
                    <Button color="danger" outline onClick={optionTots}>Tots</Button>{' '}
                    {/*<Button color="danger" outline  onClick={optionVeterans}>Máster</Button>
                    <Button color="danger" outline  onClick={optionSenior}>Sènior</Button>{' '}
                    {/*<Button color="danger" outline  onClick={optionSeniorM}>Sènior Masculí</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuniorA}>Júnior A</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuniorB}>Júnior B</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuvenilA}>Juvenil A</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuvenilB}>Juvenil B</Button>
                    <Button color="danger" outline  onClick={optionJuvenilC}>Juvenil C</Button>
                    <Button color="danger" outline  onClick={optionJuvenilM}>Juvenil M</Button>
                    <Button color="danger" outline  onClick={optionCadetA}>Cadet A</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetB}>Cadet B</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetC}>Cadet C</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetM}>Cadet M</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilA}>Infantil A</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilB}>Infantil B</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilM}>Infantil M</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviA}>Alevi A</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviB}>Alevi B</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviC}>Alevi C</Button>{' '}
                    <Button color="danger" outline  onClick={optionBenjamiA}>Benjami A</Button>{' '}
                    <Button color="danger" outline  onClick={optionBenjamiB}>Benjami B</Button>{' '}
                </div>

                <VerticalTimeline layout='2-columns' animate='true'>
                    <h1><Badge bg="secondary posPadding">DILLUNS</Badge></h1>
                    {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#C7DBF6', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C7DBF6', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C7DBF6', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C7DBF6', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  } 
                        {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FEE4CF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FEE4CF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#FEE4CF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FEE4CF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>    }  
                        {(isEquipTots || isEquipSeniorM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"C
                            contentStyle={{ background: '#00FF40', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="22:00 - 23:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#00FF40', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR MASCULÍ</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}

                        <h1><Badge bg="secondary posPadding">DIMARTS</Badge></h1>
                        {(isEquipTots || isEquipJuvenilC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#D7A6BC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D7A6BC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#63A0E7', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#63A0E7', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta</h4>
                        </VerticalTimelineElement>}  
                        {(isEquipTots|| isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#D7A6BC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:00 - 18:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D7A6BC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots || isEquipAleviE) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#EBD1DC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:15 - 19:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#EBD1DC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">BENJAMINES</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilN) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#D9EAD4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D9EAD4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ NEGRE</h3>
                            <h4 className="vertical-timeline-element-subtitle">Escorial</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetD) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FFD772', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:15 - 20:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FFD772', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJunior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 21:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JÚNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:45 - 23:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}


                        <h1><Badge bg="secondary posPadding">DIMECRES</Badge></h1>
                        {(isEquipTots|| isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C7DBF6', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C7DBF6', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots || isEquipInfantilV) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#8C7DC0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#8C7DC0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL VERMELL</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>  } 
                        {(isEquipTots|| isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#C7DBF6', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C7DBF6', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#E27C6D', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 18:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E27C6D', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ VERMELL/BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipInfantilN) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#D9EAD4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D9EAD4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ NEGRE</h3>
                            <h4 className="vertical-timeline-element-subtitle">Escorial</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FEE4CF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 20:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FEE4CF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetM) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET MASCULÍ</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>    }  
                        {(isEquipTots || isEquipJuvenilD) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#92C482', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:00 - 21:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#92C482', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P3</h4>
                        </VerticalTimelineElement>} 
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FEE4CF', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:00 - 21:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FEE4CF', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}   
                        {(isEquipTots || isEquipCadetD) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#FFD772', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 21:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FFD772', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:00 - 22:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P2</h4>
                        </VerticalTimelineElement>  }   
                        {(isEquipTots || isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#68A857', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:15 - 22:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#68A857', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">MASTER</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P3</h4>
                        </VerticalTimelineElement>  }  
                        {(isEquipTots || isEquipSeniorM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#00FF40', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:45 - 23:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#00FF40', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR MASCULÍ</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}

                        <h1><Badge bg="secondary posPadding">DIJOUS</Badge></h1>
                        {(isEquipTots || isEquipJuvenilC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#D7A6BC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D7A6BC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#D7A6BC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D7A6BC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}  
                        {(isEquipTots|| isEquipAleviE) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#EBD1DC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#EBD1DC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">BENJAMINES</h3>
                            <h4 className="vertical-timeline-element-subtitle">Escorial</h4>
                        </VerticalTimelineElement> } 
                        {(isEquipTots || isEquipInfantilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#E27C6D', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E27C6D', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVÍ VERMELL/BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilD) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#92C482', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 21:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#92C482', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#68A857', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:45 - 23:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#68A857', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">MASTER</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                                                
                        <h1><Badge bg="secondary posPadding">DIVENDRES</Badge></h1>
                        {(isEquipTots|| isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#63A0E7', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="16:00 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#63A0E7', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }
                        {(isEquipTots|| isEquipCadetM) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET MASCULÍ</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilV) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#8C7DC0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#8C7DC0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL VERMELL</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }
                        {(isEquipTots || isEquipJunior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JÚNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  }   
                        {(isEquipTots|| isEquipSenior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="22:00 - 23:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SÈNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}                   
                </VerticalTimeline>
            </div> 

            // Entrenaments 2022-2023
            <div>
                <h2> HORARIS D’ENTRENAMENT<br/>TEMPORADA 22-23 </h2>   
                <div className="interButtons">
                    <Button color="danger" outline onClick={optionTots}>Tots</Button>{' '}
                    <Button color="danger" outline  onClick={optionVeterans}>Veterans</Button>
                    <Button color="danger" outline  onClick={optionJunior}>Senior</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuvenilA}>Juvenil A</Button>{' '}
                    <Button color="danger" outline  onClick={optionJuvenilB}>Juvenil B</Button>
                    <Button color="danger" outline  onClick={optionJuvenilC}>Juvenil C</Button>
                    <Button color="danger" outline  onClick={optionJuvenilD}>Juvenil D</Button>
                    <Button color="danger" outline  onClick={optionCadetA}>Cadet A</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetB}>Cadet B</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetC}>Cadet C</Button>{' '}
                    <Button color="danger" outline  onClick={optionCadetD}>Cadet D</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilV}>Infantil Vermell</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilB}>Inantil Blanc</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilM}>Infantil Masculí</Button>{' '}
                    <Button color="danger" outline  onClick={optionInfantilN}>Inantil Negre</Button>{' '}
                    <Button color="danger" outline  onClick={optionAleviE}>Alevines/benjamines</Button>{' '}
                </div>
                <VerticalTimeline layout='2-columns' animate='true'>
                    <h1><Badge bg="secondary posPadding">DILLUNS</Badge></h1>
                    {(isEquipTots || isEquipJuvenilC) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#D7A6BC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D7A6BC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilV) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B3A8D4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B3A8D4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL VERMELL</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  } 
                        {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#FFD772', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 21:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FFD772', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>    }  
                        <h1><Badge bg="secondary posPadding">DIMARTS</Badge></h1>
                        {(isEquipTots || isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B85D1A', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B85D1A', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipAleviE) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVINES/BENJAMINES</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}  
                        {(isEquipTots|| isEquipInfantilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#E27C6D', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:15 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E27C6D', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL MASCULÍ</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots || isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#92C482', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:45 - 21:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#92C482', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#EE989A', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:15 - 22:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#EE989A', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">VETERANS</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        <h1><Badge bg="secondary posPadding">DIMECRES</Badge></h1>
                        {(isEquipTots|| isEquipInfantilN) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL NEGRE</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }  
                        {(isEquipTots || isEquipInfantilV) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B3A8D4', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B3A8D4', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL VERMELL</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  } 
                        {(isEquipTots|| isEquipCadetD) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#B5D7AB', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5D7AB', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#8C7DC0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:00 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#8C7DC0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#92C482', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:00 - 21:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#92C482', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P2</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#FFD772', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 21:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FFD772', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>    }  
                        {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P1</h4>
                        </VerticalTimelineElement>}   
                        {(isEquipTots || isEquipJuvenilD) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#68A857', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:15 - 22:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#68A857', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Ausoneta P2</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJunior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B5E1CE', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:45 - 23:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5E1CE', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SENIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  }     
                        <h1><Badge bg="secondary posPadding">DIJOUS</Badge></h1>
                        {(isEquipTots || isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B85D1A', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B85D1A', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipAleviE) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#FDCAA0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#FDCAA0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVINES/BENJAMINES</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}  
                        {(isEquipTots|| isEquipInfantilM) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#E27C6D', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:15 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#E27C6D', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL MASCULÍ</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> } 
                        {(isEquipTots || isEquipJuvenilC) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#D7A6BC', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:45 - 21:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#D7A6BC', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilD) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#68A857', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:15 - 22:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#68A857', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        <h1><Badge bg="secondary posPadding">DIVENDRES</Badge></h1>
                        {(isEquipTots|| isEquipInfantilN) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#9FC4C9', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#9FC4C9', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL NEGRE</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }
                        {(isEquipTots|| isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: '#8C7DC0', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:15 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#8C7DC0', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#C57B9F', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#C57B9F', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }
                        {(isEquipTots || isEquipJunior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#B5E1CE', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 21:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#B5E1CE', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">SENIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  }   
                        {(isEquipTots|| isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: '#EE989A', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:45 - 23:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: '#EE989A', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">VETERANS</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}                   
                </VerticalTimeline>
            </div> 
            */
/*
            <div>
                <h2> HORARIS D’ENTRENAMENT<br/>TEMPORADA 21-22 </h2>   
                <div className="interButtons">
                    <Button variant="outline-danger" onClick={optionTots}>Tots</Button>{' '}
                    <Button variant="outline-danger" onClick={optionJunior}>Júnior</Button>{' '}
                    <Button variant="outline-danger" onClick={optionJuvenilA}>Juvenil A</Button>{' '}
                    <Button variant="outline-danger" onClick={optionJuvenilB}>Juvenil B</Button>
                    <Button variant="outline-danger" onClick={optionCadetA}>Cadet A</Button>{' '}
                    <Button variant="outline-danger" onClick={optionCadetB}>Cadet B</Button>{' '}
                    <Button variant="outline-danger" onClick={optionCadetC}>Cadet C</Button>{' '}
                    <Button variant="outline-danger" onClick={optionCadetD}>Cadet D</Button>{' '}
                    <Button variant="outline-danger" onClick={optionInfantilV}>Infantil Vermell</Button>{' '}
                    <Button variant="outline-danger" onClick={optionInfantilB}>Inantil Blanc</Button>{' '}
                    <Button variant="outline-danger" onClick={optionAleviE}>Alevi E</Button>{' '}
                    <Button variant="outline-danger" onClick={optionAleviN}>Alevi N</Button>{' '}
                    <Button variant="outline-danger" onClick={optionVeterans}>Veterans</Button>
                </div>
                <VerticalTimeline layout='2-columns' animate='true'>
                    <h1><Badge bg="secondary posPadding">DILLUNS</Badge></h1>
                    {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'lightgrey', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:45 - 19:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightgrey', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: 'magenta', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:30 - 21:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'magenta', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'lightgoldenrodyellow', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="21:15 - 22:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightgoldenrodyellow', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">VETERANS</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                    <h1><Badge bg="secondary posPadding">DIMARTS</Badge></h1>
                        {(isEquipTots || isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'white', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'white', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipAleviE|| isEquipAleviN) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'moccasin', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:15 - 19:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'moccasin', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVI E I ALEVI N</h3>
                            <h4 className="vertical-timeline-element-subtitle">Pavelló Ausoneta (Dues pistes de 18:30-19:30)</h4>
                        </VerticalTimelineElement>}          
                        {(isEquipTots|| isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: 'lightblue', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightblue', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'coral', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'coral', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }                
                    <h1><Badge bg="secondary posPadding">DIMECRES</Badge></h1>
                        {(isEquipTots || isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'green', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'green', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipAleviE) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: 'yellow', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'yellow', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVI E</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipInfantilV) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'chocolate', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 20:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'chocolate', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL VERMELL</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  }   
                        {(isEquipTots || isEquipCadetA) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'lightgrey', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:45 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightgrey', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Pavelló Ausoneta</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipCadetD) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'lightblue', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:45 - 21:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightblue', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET D</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilA) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: 'magenta', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 22:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'magenta', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL A</h3>
                            <h4 className="vertical-timeline-element-subtitle">Pavelló Ausoneta</h4>
                        </VerticalTimelineElement>    }                                        
                        {(isEquipTots|| isEquipVeterans) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'lightgoldenrodyellow', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 22:15"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightgoldenrodyellow', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">VETERANS</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                    <h1><Badge bg="secondary posPadding">DIJOUS</Badge></h1>
                        {(isEquipTots|| isEquipAleviN) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'lightgreen', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightgreen', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">ALEVI N</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipInfantilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'white', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 18:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'white', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL BLANC</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }                       
                        {(isEquipTots|| isEquipCadetB) && <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{ background: 'lightblue', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="18:30 - 20:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'lightblue', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots || isEquipJuvenilB) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'coral', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="20:15 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'coral', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JUVENIL B</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}   
                    <h1><Badge bg="secondary posPadding">DIVENDRES</Badge></h1>
                        {(isEquipTots|| isEquipCadetC) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'green', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="15:45 - 17:45"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'green', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">CADET C</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>}
                        {(isEquipTots|| isEquipInfantilV) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'chocolate', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="17:30 - 19:30"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'chocolate', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">INFANTIL VERMELL</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement> }                     
                        {(isEquipTots || isEquipJunior) && <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'mediumorchid', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="19:30 - 22:00"
                            dateClassName="dateStyle"
                            iconStyle={{ background: 'mediumorchid', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">JÚNIOR</h3>
                            <h4 className="vertical-timeline-element-subtitle">Callís</h4>
                        </VerticalTimelineElement>  }                         
                </VerticalTimeline>
            </div> 
            
            npm i react-calendar-timeline  (per calendari)

                                <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2002 - 2006"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}>
                        <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
                        <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                        <p>
                        Creative Direction, Visual Design
                        </p>
                    </VerticalTimelineElement>
                    
            <Timetable 
            events={{
                monday: [
                {
                    id: 1,
                    name: "Custom Event 1",
                    type: "custom",
                    startTime: new Date("2018-02-23T11:30:00"),
                    endTime: new Date("2018-02-23T13:30:00"),
                },
                ],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
            }}
            hoursInterval =  {{
                "from": 15,
                "to": 23
              }}
            timeLabel = 'Entrenaments'
              
            />
            */
        )
}

export default Entrenaments