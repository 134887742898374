import ImageGallery from 'react-image-gallery';
import { useNavigate } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import './voleiGallery.scss';

const VoleiGallery = (props) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/voleialbums');
  }

  const amplada = window.innerWidth;
  const images1 = [
        {
          original: '/img/album1/1.jpg',
          thumbnail: '/img/album1/1p.jpg',
        },
        {
          original: '/img/album1/2.jpg',
          thumbnail: '/img/album1/2p.jpg',
        },
        {
          original: '/img/album1/3.jpg',
          thumbnail: '/img/album1/3p.jpg',
        },
        {
          original: '/img/album1/4.jpg',
          thumbnail: '/img/album1/4p.jpg',
        },
        {
          original: '/img/album1/5.jpg',
          thumbnail: '/img/album1/5p.jpg',
        },
        {
          original: '/img/album1/6.jpg',
          thumbnail: '/img/album1/6p.jpg',
        },
        {
          original: '/img/album1/7.jpg',
          thumbnail: '/img/album1/7p.jpg',
        },
        {
          original: '/img/album1/8.jpg',
          thumbnail: '/img/album1/8p.jpg',
        },
        {
          original: '/img/album1/9.jpg',
          thumbnail: '/img/album1/9p.jpg',
        },
        {
          original: '/img/album1/10.jpg',
          thumbnail: '/img/album1/10p.jpg',
        },
        {
          original: '/img/album1/11.jpg',
          thumbnail: '/img/album1/11p.jpg',
        },
        {
          original: '/img/album1/12.jpg',
          thumbnail: '/img/album1/12p.jpg',
        },
        {
          original: '/img/album1/13.jpg',
          thumbnail: '/img/album1/13p.jpg',
        },
        {
          original: '/img/album1/14.jpg',
          thumbnail: '/img/album1/14p.jpg',
        },
      ];

      const images2 = [
        {
          original: '/img/album2/1.jpg',
          thumbnail: '/img/album2/1p.jpg',
        },
        {
          original: '/img/album2/2.jpg',
          thumbnail: '/img/album2/2p.jpg',
        },
        {
          original: '/img/album2/3.jpg',
          thumbnail: '/img/album2/3p.jpg',
        },
        {
          original: '/img/album2/4.jpg',
          thumbnail: '/img/album2/4p.jpg',
        },
        {
          original: '/img/album2/5.jpg',
          thumbnail: '/img/album2/5p.jpg',
        },
        {
          original: '/img/album2/6.jpg',
          thumbnail: '/img/album2/6p.jpg',
        },
        {
          original: '/img/album2/7.jpg',
          thumbnail: '/img/album2/7p.jpg',
        },
        {
          original: '/img/album2/8.jpg',
          thumbnail: '/img/album2/8p.jpg',
        },
        {
          original: '/img/album2/9.jpg',
          thumbnail: '/img/album2/9p.jpg',
        },
        {
          original: '/img/album2/10.jpg',
          thumbnail: '/img/album2/10p.jpg',
        },
        {
          original: '/img/album2/11.jpg',
          thumbnail: '/img/album2/11p.jpg',
        },
        {
          original: '/img/album2/12.jpg',
          thumbnail: '/img/album2/12p.jpg',
        },
        {
          original: '/img/album2/13.jpg',
          thumbnail: '/img/album2/13p.jpg',
        },
        {
          original: '/img/album2/14.jpg',
          thumbnail: '/img/album2/14p.jpg',
        },
        {
          original: '/img/album2/15.jpg',
          thumbnail: '/img/album2/15p.jpg',
        },
        {
          original: '/img/album2/16.jpg',
          thumbnail: '/img/album2/16p.jpg',
        },
        {
          original: '/img/album2/17.jpg',
          thumbnail: '/img/album2/17p.jpg',
        },
        {
          original: '/img/album2/18.jpg',
          thumbnail: '/img/album2/18p.jpg',
        },
        {
          original: '/img/album2/19.jpg',
          thumbnail: '/img/album2/19p.jpg',
        },
        {
          original: '/img/album2/20.jpg',
          thumbnail: '/img/album2/20p.jpg',
        },
        {
          original: '/img/album2/21.jpg',
          thumbnail: '/img/album2/21p.jpg',
        },
        {
          original: '/img/album2/22.jpg',
          thumbnail: '/img/album2/22p.jpg',
        },
        {
          original: '/img/album2/23.jpg',
          thumbnail: '/img/album2/23p.jpg',
        },
        {
          original: '/img/album2/24.jpg',
          thumbnail: '/img/album2/24p.jpg',
        },
        {
          original: '/img/album2/25.jpg',
          thumbnail: '/img/album2/25p.jpg',
        },
        {
          original: '/img/album2/26.jpg',
          thumbnail: '/img/album2/26p.jpg',
        },
        {
          original: '/img/album2/27.jpg',
          thumbnail: '/img/album2/27p.jpg',
        },
        {
          original: '/img/album2/28.jpg',
          thumbnail: '/img/album2/28p.jpg',
        },
        {
          original: '/img/album2/29.jpg',
          thumbnail: '/img/album2/29p.jpg',
        },
      ];

      const images3 = [
        {
          original: '/img/album3/1.png',
          thumbnail: '/img/album3/1p.png',
        },
        {
          original: '/img/album3/2.png',
          thumbnail: '/img/album3/2p.png',
        },
        {
          original: '/img/album3/3.png',
          thumbnail: '/img/album3/3p.png',
        },
        {
          original: '/img/album3/4.png',
          thumbnail: '/img/album3/4p.png',
        },
        {
          original: '/img/album3/5.png',
          thumbnail: '/img/album3/5p.png',
        },
        {
          original: '/img/album3/6.png',
          thumbnail: '/img/album3/6p.png',
        },
        {
          original: '/img/album3/7.png',
          thumbnail: '/img/album3/7p.png',
        },
        {
          original: '/img/album3/8.png',
          thumbnail: '/img/album3/8p.png',
        },
        {
          original: '/img/album3/9.png',
          thumbnail: '/img/album3/9p.png',
        },
        {
          original: '/img/album3/10.png',
          thumbnail: '/img/album3/10p.png',
        },
        {
          original: '/img/album3/11.png',
          thumbnail: '/img/album3/11p.png',
        },
        {
          original: '/img/album3/12.png',
          thumbnail: '/img/album3/12p.png',
        },
        {
          original: '/img/album3/13.png',
          thumbnail: '/img/album3/13p.png',
        },
        {
          original: '/img/album3/14.png',
          thumbnail: '/img/album3/14p.png',
        },
        {
          original: '/img/album3/15.png',
          thumbnail: '/img/album3/15p.png',
        },
        {
          original: '/img/album3/16.png',
          thumbnail: '/img/album3/16p.png',
        },
        {
          original: '/img/album3/17.png',
          thumbnail: '/img/album3/17p.png',
        },
        {
          original: '/img/album3/18.png',
          thumbnail: '/img/album3/18p.png',
        },
        {
          original: '/img/album3/18e.png',
          thumbnail: '/img/album3/18ep.png',
        },
        {
          original: '/img/album3/19.png',
          thumbnail: '/img/album3/19p.png',
        },
        {
          original: '/img/album3/20.png',
          thumbnail: '/img/album3/20p.png',
        },
        {
          original: '/img/album3/21.png',
          thumbnail: '/img/album3/21p.png',
        },
        {
          original: '/img/album3/22.png',
          thumbnail: '/img/album3/22p.png',
        },
        {
          original: '/img/album3/23.png',
          thumbnail: '/img/album3/23p.png',
        },
        {
          original: '/img/album3/24.png',
          thumbnail: '/img/album3/24p.png',
        },
        {
          original: '/img/album3/25.png',
          thumbnail: '/img/album3/25p.png',
        },
        {
          original: '/img/album3/26.png',
          thumbnail: '/img/album3/26p.png',
        },
        {
          original: '/img/album3/27.png',
          thumbnail: '/img/album3/27p.png',
        },
        {
          original: '/img/album3/28.png',
          thumbnail: '/img/album3/28p.png',
        },
        {
          original: '/img/album3/29.png',
          thumbnail: '/img/album3/29p.png',
        },
        {
          original: '/img/album3/30.png',
          thumbnail: '/img/album3/30p.png',
        },
        {
          original: '/img/album3/31.png',
          thumbnail: '/img/album3/31p.png',
        },
        {
          original: '/img/album3/32.png',
          thumbnail: '/img/album3/32p.png',
        },
        {
          original: '/img/album3/33.png',
          thumbnail: '/img/album3/33p.png',
        },
        {
          original: '/img/album3/34.png',
          thumbnail: '/img/album3/34p.png',
        },
        {
          original: '/img/album3/35.png',
          thumbnail: '/img/album3/35p.png',
        },
        {
          original: '/img/album3/36.png',
          thumbnail: '/img/album3/36p.png',
        },
        {
          original: '/img/album3/37.png',
          thumbnail: '/img/album3/37p.png',
        },
        {
          original: '/img/album3/38.png',
          thumbnail: '/img/album3/38p.png',
        },
        {
          original: '/img/album3/39.png',
          thumbnail: '/img/album3/39p.png',
        },
        {
          original: '/img/album3/40.png',
          thumbnail: '/img/album3/40p.png',
        },
        {
          original: '/img/album3/41.png',
          thumbnail: '/img/album3/41p.png',
        },
        {
          original: '/img/album3/42.png',
          thumbnail: '/img/album3/42p.png',
        },
        {
          original: '/img/album3/43.png',
          thumbnail: '/img/album3/43p.png',
        },
      ];

    let images = [];
    let title = '';

    console.log('idalbum ' + props.idalbum)
    switch (props.idalbum) {
      case 1: images = images1; title= 'Presentació temporada 21-22'; break;
      case 2: images = images2; title= 'Presentació temporada 22-23'; break;
      case 3: images = images3; title= 'Presentació temporada 23-24'; break;
      default: break;
    }

    console.log('title');

    console.log(title);
    console.log('images');

    console.log(images);

    return (
      <div>
        <Button color="primary" onClick={handleBackClick}>Tornar</Button>
        <Alert variant='primary'>
            <h1>{title}</h1>
        </Alert>
        <br/><br/>
        {images ? <ImageGallery items={images} thumbnailPosition='bottom' /> : null}
      </div>
    )
      
}

export default VoleiGallery;
