import React, { useState } from 'react'
import { init, send } from 'emailjs-com'

import './Contact.css'
import { Card, Row, Col, Alert, Badge, Figure, Button } from 'react-bootstrap'
import { Form, Input } from 'semantic-ui-react'
import * as IconName from "react-icons/fi";


const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const isValidEmail = email => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
        const TEMPLATE_ID = process.env.REACT_APP_CONTACT_TEMPLATE_ID;
        const USER_ID = process.env.REACT_APP_USER_ID;
        const Swal = require('sweetalert2')

        const templateParams = {
            name,
            email,
            message
        };

        if (!(isValidEmail(email))){
            Swal.fire({
                icon: 'info',
                title: 'Correu incorrecte',
                toast: true,
                position: 'top',
                timer: 3000,
                showConfirmButton: false
              }) 
        } else {
            send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
            .then((result) => {
              console.log(result.text);
              Swal.fire({
                  icon: 'success',
                  text: 'ens posarem en contacte el més aviat possible',
                  title: 'Missatge enviat correctament',
                  toast: true,
                  position: 'top',
                  timer: 3000,
                  showConfirmButton: false
                }) 
              setName('');
              setEmail('');
              setMessage('');;
            }, (error) => {
              console.log(error.text);
              Swal.fire({
                icon: 'error',
                title: 'Ui, alguna cosa no ha funcionat',
                text: error.text,
                toast: true,
                position: 'top',
                timer: 3000,
                showConfirmButton: false
              })
            }); 
          }
        e.target.reset()
      };

    init('user_Yk3LBKTPzdA96IdP6TLg8');

    return (
        <div className='Contact'>
            <Row>
                <Col>
                    <br />
                    <Alert variant='danger'>
                        Dades de contacte del Club
                    </Alert>

                    <Card>
                        <Figure>
                            <Figure.Image
                            width={280}
                            height={180}
                            alt="171x180"
                            src="img/Logop.png" />
                        </Figure>
                        <Card.Header>Vic Vòlei Callís</Card.Header>
                        <Card.Body>
                            <Card.Subtitle className="mb-2">Podeu contactar amb nosaltres a</Card.Subtitle>
                            <Card.Text><IconName.FiMapPin className="me-2"  />Adreça:<br/>Avinguda d'Olímpia, 2<br/>08500 Vic, Barcelona</Card.Text>

                            <Card.Text><IconName.FiAtSign className="me-2"  />correu Informatiu/General:<br/>
                                <a href="mailto:callis.volei@gmail.com">callis.volei@gmail.com</a><br/></Card.Text>  

                            <Card.Text><IconName.FiAtSign className="me-2"  />correu Casals/Campus:<br/>
                                <a href="mailto:campus@vicvoleicallis.cat">campus@vicvoleicallis.cat</a><br/></Card.Text>  
                
                            <Card.Text><IconName.FiAtSign className="me-2"  />correu Esportiu:<br/>
                                <a href="mailto:equips@vicvoleicallis.cat">equips@vicvoleicallis.cat</a><br/></Card.Text>  

                            <Card.Text><IconName.FiWatch className="me-2"  />horari secretaria:<br/>dimecres de 17h-18:30h<br/>dijous de 18:15h-20:15h</Card.Text>                  
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <br />
                    <Alert variant='danger'>
                        Tens algun dubte? <br />  Escriu-nos i us respondrem el més aviat possible <Badge bg="secondary">Contacte amb nosaltres</Badge>
                    </Alert>

                    <Form onSubmit={handleOnSubmit}>
                        <Form.Field
                            id="form-input-control-email"
                            control={Input}
                            label="Email"
                            name="user_email"
                            placeholder="Email…"
                            required
                            icon="mail"
                            iconPosition="left"
                            value={email} onChange={e => setEmail(e.target.value)} />
                        
                        <Form.Field
                            id='form-input-control-last-name'
                            control={Input}
                            label='Nom'
                            name='user_name'
                            placeholder='Nom…'
                            required
                            icon='user circle'
                            iconPosition='left'
                            value={name} onChange={e => setName(e.target.value)} /> 
                        
                        <Button type='submit' color='green'>Enviar</Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Contact;